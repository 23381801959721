.dotted_row {
  border-bottom: 0.5px dashed #2eb5b9 !important;
  color: #2eb5b9 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  cursor: default;
}

.spacingBetweenRowsCollapse {
  border-collapse: collapse ;
  cursor: default;
}
