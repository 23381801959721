.linearBackgroundFullScreen {
  background: linear-gradient(
    180deg,
    rgba(113, 208, 22, 0.63) 0%,
    rgba(59, 174, 185, 0.83) 51.04%,
    #0a4364 99.73%
  );
  height: 100vh;
}

body {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #0a4364;
  background-color: #efefef;
}

.navbarGradient {
  background: linear-gradient(
    90deg,
    rgba(113, 208, 22, 0.63) 0%,
    rgba(59, 174, 185, 0.83) 51.04%,
    #2eb5b9 99.73%
  );
  height: fit-content;
}

.navbarButton {
  background-color: transparent !important;
  border: none;
  margin-right: 25px;
  margin-left: 30px;
}

.navItems {
  display: inline;
  background-color: #d8eff1;
  color: #1c4261;
  height: fit-content;
  width: 100px !important;
  padding: 5px 28px;
  margin: 2px;
  text-decoration: none;
  font-weight: 400 !important;
  font-size: x-large;
}

.navItemsBtn1 {
  background-color: #d8eff1;
  color: #1c4261;
  height: fit-content;
  font-size: x-large;
  width: 100px !important;
  border-radius: 25px 0px 0px 25px;
  padding: 5px 28px;
  margin: 10px 2px;
  text-decoration: none;
  font-weight: 400 !important;
}

.navItemsSingle {
  background-color: #d8eff1;
  color: #1c4261;
  height: fit-content;
  font-size: x-large;
  width: 100px !important;
  border-radius: 25px 25px 25px 25px;
  padding: 5px 28px;
  margin: 10px 2px;
  text-decoration: none;
  font-weight: 400 !important;
}

.navItemsBtnlast {
  background-color: #d8eff1;
  color: #1c4261;
  height: fit-content;
  width: 100px !important;
  border-radius: 0px 25px 25px 0px;
  padding: 5px 28px;
  font-size: x-large;
  margin: 2px;
  text-decoration: none;
  font-weight: 400 !important;
}

.active {
  background-color: #fff !important;
}

.skyBlue {
  background-color: #d8eff1 !important
      ;
}

.text_color_SkyBlue {
  color: #2eb5b9;
}

.form-control::-webkit-input-placeholder {
  color: #d8eff1;
}

.border_color_skyBlue {
  border-color: #d8eff1;
}
.buttonStyle {
  /* font-weight: 700 !important; */
  border-radius: 15px;
  /* width: 10%;
  height: 10% !important; */
}

.belowNav {
  position: relative;
  top: 160px;
  left: auto;
  padding: 0px;
  margin: 0px;
}

.Header_Text {
  margin-bottom: 0px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 39px;
  color: #1c4261;
  letter-spacing: 0.01em;
}

.Content_subheader {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #0a4364;
}

.subHeader {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #0a4364;
}
.highcharts-credits {
  display: none !important;
}
.highcharts-data-label-connector {
  display: none !important;
}
::-webkit-scrollbar-track {
  /* width: 300px;
  height: 100px; */
  /* overflow-y: scroll; */
  background-color: #afaeae;
  /* scrollbar-color: #2eb5b9 #afaeae; */
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-thumb {
  background-color: #3baeb9;
  border-radius: 15px;
}
::-webkit-scrollbar-thumb {
  height: 103px;
}

.textarea {
  outline: none !important;
  background: #fff;
}

.form-control {
  box-shadow: none !important;
}

::-webkit-input-placeholder {
  font-size: 25px;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-size: 25px;
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 25px;
}

/* Overriding styles */

::-webkit-input-placeholder {
  font-size: 13px !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-size: 13px !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 13px !important;
}
.SupervisorDashboard {
  margin-top: 2%;
  color: transparent;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 39px;
  letter-spacing: 0.01em;
}
.row {
  width: 100% !important;
}

.backGroundWhite {
  background-color: #fff;
}

.backGroundSkyBlue {
  background-color: #2eb5b9;
}

.NoDataColor1 {
  font-weight: 600;
  color: #3baeb9;
  /* color: #5adde9; */
}
.pieChartSpinner {
  margin-top: 50px;
}
.highcharts-figure,
.highcharts-data-table table {
  min-width: 310px;
  max-width: 800px;
  margin: 1em auto;
}

#container {
  height: 400px;
}
/* 
.highcharts-tooltip h3 {
  margin: 0.3em 0;
} */

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}
.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}
.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}
.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}
.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}
.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

.RequiredCount {
  margin-top: 1%;
  color: #59b70f;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 29px;
  line-height: 19px;
}

.inputPlaceholder::placeholder {
  color: rgb(201, 230, 233) !important;
  opacity: 1 !important;
}

@media screen and (max-width: 1366px) {
  .row {
    padding-left: 0px;
  }
  .col-2 {
    padding: 0px;
    margin: 0px;
  }
  .high-risk-icon {
    width: 120% !important;
  }
  .Header_Text {
    font-size: 31px;
  }
}

@media (min-width: 1100px) and (max-width: 1300px) {
  .navItems {
    font-size: 20px;
    padding: 0px 15px 0px 15px;
  }
  .navItemsBtn1 {
    font-size: 20px;
    padding: 0px 15px 0px 15px;
  }
  .navItemsBtnlast {
    font-size: 20px;
    padding: 0px 15px 0px 15px;
  }
  .logoImg {
    width: 180px;
    height: 45px;
    padding-right: 10px;
  }
  .heading {
    font-size: 20px !important;
  }
  .row {
    --bs-gutter-x: 0.62rem;
  }
}

.bubble-tooltip {
  margin-left: 20px;
}
