.cardButton {
  background-color: white;
  bottom: 12px;
  border-radius: 15px;
  border: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #3baeb9;
}
.cardHeader {
  font-family: Roboto;
  font-style: normal;
  /* font-weight: 500; */
  font-size: 36px;
  line-height: 42px;

  letter-spacing: 0.01em;
  text-decoration-line: underline;
  color: #3baeb9;
}

.nameStyleP {
  font-weight: 500 !important;
  font-size: 36px !important;
  line-height: 42px !important;
  letter-spacing: 0.01em !important;
  text-decoration: underline !important;
  color: #3baeb9 !important;
}

.whiteCardHeader {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 36px !important;
  line-height: 42px !important;

  letter-spacing: 0.01em !important;
  text-decoration-line: underline !important;
  color: #1c4261 !important;
}

.tableStyle {
  color: #2eb5b9;
  padding: 10px;
}
.sideTableHaead {
  color: #d8eff1;
  line-height: 20px !important;
}
.tbodyBorder {
  border-bottom: 0.5px dashed #2eb5b9;
  color: #2eb5b9;
  font-weight: normal;
}
td {
  padding: 0.5em;
  font-weight: "normal";
}

.cardtd {
  color: black;
  font-weight: normal;
  line-height: 25px;
}

.secondColHeader {
  font-weight: 600 !important;
  font-size: 30.5px;
  line-height: 39px;
  letter-spacing: 0.01em;
  color: #1c4261;
  font-family: Raleway;
}
.secondCol2ndHeader {
  font-weight: normal;
  font-size: 25px;
  line-height: 42px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #1c4261;
}

.valueColor {
  color: #2eb5b9;
}

.secondRowWhiteDiv {
  background-color: "white";
  width: "95%";
  border-radius: 15px;
}

.EbuttonStyle {
  border: none;
  background-color: #d8eff1;
  width: 123.96px;
  height: 43px;
  border-radius: 15px 0px 0px 15px;
  color: #3baeb9;
}
.IbuttonStyle {
  border: none;
  background-color: #d8eff1;
  width: 123.96px;
  height: 43px;
  color: #3baeb9;
}
.TbuttonStyle {
  border: none;
  background-color: #d8eff1;
  width: 123.96px;
  height: 43px;
  border-radius: 0px 15px 15px 0px;
  color: #3baeb9;
}

.activeSelectedButton {
  background-color: rgba(59, 174, 185, 0.4) !important;
}

.highRiskstyle {
  color: #ffb400;
  font-weight: 500 !important;
  font-size: 18px;
  margin-left: 2%;
}

.innerTdData {
  font-weight: normal;
}

.patientDetailsCard {
  position: relative;
  height: 32em;
  box-sizing: border-box;
  background: #d8eff1;
  border-radius: 15px;
}
.upcomingSurvey-div {
  width: 19%;
}

.patientDetails {
  width: 59%;
  background-color: white;
  border-radius: 15px;
  margin-left: 8em;
  margin-top: 37em;
}
.dataNoAvailableContainer {
  margin-top: 20em;
  height: 20px;
}
.NoDataColor {
  position: relative;
  font-size: 16px;
  /* top: -250px; */
}
@media screen and (min-device-width: 1400px) {
  .patientDetailsCard {
    height: 75vh;
  }
}
/* @media screen and (min-width: 1400px) {
  .patientDetailsCard {
    height: 75vh;
  } */
/* } */
/* @media screen and (min-width: 1200px) and (max-width: 1399px) {
  .patientDetailsCard {
    height: 70vh;
  }
} */
.patientDetails {
  width: 57%;
  margin-left: 9.4em;
}

.patientDetaisCardHeight {
  max-height: 100vh;
}
@media screen and (min-width: 1400px) {
  .patientDetaisCardHeight {
    max-height: 68vh;
  }

  /* @media screen and (min-width: 1200px) {
  .patientDetaisCardHeight {
    max-height: 64vh;
  } */
  .upcomingSurvey-div {
    width: 20%;
  }
}
.AceTitle {
  font-family: Raleway !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 30.5px !important;
  line-height: 39px !important;
  letter-spacing: 0.01em !important;
  color: #0a4364 !important;
}
.upcomingdiv {
  margin-top: 15vh !important;
}
.AceSubtitle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #0a4364;
}
.SFSS-rapper-chart {
  @media (max-width: 1199px) .col-md-9 {
    flex: 0 0 auto;
    width: 90%;
  }
}
/* .highcharts-root {
  width: 95% !important;

  margin-top: -9px !important;
  margin-left: 4px !important;
} */
