.fontSize20 {
  font-size: 20px !important;
}

.displayNo {
  display: none !important;
}

.fontSize19 {
  font-size: 19px !important;
}
.rowData {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;

  color: #3baeb9 !important;
}

.table-responsive {
  overflow: hidden !important;
  height: 0% !important;
}

.divTopAlignment {
  padding-top: 5%;
}
.bgColor {
  background-color: #efefef;
  margin: 1% !important;
  padding: 1% !important;
  border-radius: 3% !important;
  cursor: pointer;
}

.divSurveyDates {
  height: 65vh !important;
  overflow-y: auto !important;
}
/* .divDrpPatient {
  margin: 0% !important;
} */

.color_red {
  color: rgb(238, 51, 51) !important;
}
.color_green {
  color: rgb(96, 236, 96) !important;
}
.color_yellow {
  color: rgb(228, 207, 23) !important;
}
.color_gray {
  color: #cdd3cd !important;
}

.alignRight {
  float: right !important;
}

.btn:hover {
  color: #3495a1 !important;
}
.switch input {
  display: none;
}
.switch {
  display: inline-block;
  width: 60px;
  height: 30px;
  margin: 8px;
  transform: translateY(50%);
  position: relative;
  width: 56px;
  height: 26px;
  margin-top: 17px;
}
/* Style Wired */
.slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 30px;
  box-shadow: 0 0 0 2px #777, 0 0 4px #777;
  cursor: pointer;
  border: 4px solid transparent;
  overflow: hidden;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #777;
  border-radius: 30px;
  transform: translateX(-30px);
  transition: 0.4s;
}

input:checked + .slider:before {
  transform: translateX(30px);
  background: limeGreen;
}
input:checked + .slider {
  box-shadow: 0 0 0 2px limeGreen, 0 0 2px limeGreen;
}

/* Style Flat */
.switch.flat .slider {
  box-shadow: none;
}
.switch.flat .slider:before {
  background: #fff;
}
.switch.flat input:checked + .slider:before {
  background: white;
}
.switch.flat input:checked + .slider {
  background: limeGreen;
}
.NoDataColorForLabelled {
}
@media (min-width: 1300px) and (max-width: 1400px){
  .patient-search-outer-block{
    height: 83vh;
    margin-top: 90px;
  }
  .divSurveyDates {
    margin-top: 6px;
    height: 58vh !important;
    overflow-y: auto !important;
}
}

@media (min-width: 1400px) {
  .patient-search-outer-block{
    height: 80vh;
    margin-top: 96px;
  }
  .divSurveyDates {
    margin-top: 7px;
    height: 56vh !important;
    overflow-y: auto !important;
}
/* .spinnerss-grow {
 
  margin-top: calc( 100vh / 2);
} */
}
  @media (min-width: 1000px) and (max-width: 1299px){
    .patient-search-outer-block{
    
      height: 83vh;
      margin-top: 86px;
    }
    .divSurveyDates {
      margin-top: 7px;
      height: 54vh !important;
      overflow-y: auto !important;
  }
  .labelling-count-text{
    font-size: 1em !important;
  }
  /* .spinnerss-grow {
 
    margin-top: calc( 150vh / 2);
  } */
}
@media (min-width: 1000px) and (max-width: 1190px){
  .patient-search-outer-block{

    height: 80vh;
    margin-top: 86px;
  }
  .divSurveyDates {
    margin-top: 7px;
    height: 49vh !important;
    overflow-y: auto !important;
}
.labelling-count-text{
  font-size: 0.83em !important;
}
/* .spinnerss-grow {
 
  margin-top: calc( 150vh / 2);
} */
}
/* .spinnerss-grow {
  z-index: 1;
  left: 0;
  right: 0;
  top: 104%;
  bottom: 0;
  background-color: transparent;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow;
  display: block;
  margin-top: calc( 100vh / 2);
} */