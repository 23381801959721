.divVoiceSample{
    height:70vh !important;
    /* overflow-y: auto !important; */
}
.radioBtn{
    border-color: #3baeb9;
}
.form-check-input:checked {
    background-color:  #3baeb9;
    border-color:  #3baeb9;
}
.form-check-input:focus {
    box-shadow:none;
}
.fontSize20 {
    font-size: 20px !important;
  }
  
  .displayNo {
    display: none !important;
  }
  
  .fontSize19 {
    font-size: 19px !important;
  }
  .rowData {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  
    color: #3baeb9 !important;
  }
  
  .table_th_td_Width {
    overflow: hidden !important;
    height: 0% !important;
  }
  
  .table_th_td_Width th td {
    width: 100% !important;
  }
  
  .btnFileUpload {
    color: #fff !important;
    border-radius: 15px !important;
    align-items: center;
    text-align: center;
    letter-spacing: 0.01em;
    width: 100%;
    background-color: #3baeb9 !important;
    font-weight: 500 !important;
    line-height: 25px !important;
    padding: 1.2% !important;
  }
  
  .patientCodeStyle {
    font-size: 36px;
    text-decoration: underline;
    font-family: Roboto;
    color: #3baeb9;
    background-color: #fff !important;
    text-align: center;
    margin-bottom: 0 !important;
  }
  
  .divPrgressBar {
    display: inline-grid;
    width: 100%;
    background-color: #cecece;
  }
  .borderRaduis {
    border-radius: 15px;
  }
  .btnAudio {
    width: 16% !important;
    padding: 2% !important;
    margin-left: 12% !important;
    margin-top: 3% !important;
  }
  
  .progressbarColor {
    background-color: #3baeb9 !important;
    margin-left: 2px;
  }
  
  .btnAudioIcons {
    background-color: #d8eff1 !important;
    border: 0 solid #3baeb9 !important;
    color: #0a4364 !important;
    border-radius: 15px !important;
    font-weight: 400 !important;
    margin: 0.5% !important;
  }
  
  .tittleStyle {
    font-size: 36px;
    text-decoration: underline;
    font-family: Roboto;
    color: #3baeb9;
    background-color: #fff !important;
    /* text-align: center; */
    margin-bottom: 0 !important;
  }
  
  .tableCollapse tr {
    margin-top: 0% !important;
  }
  
  .tableCollapse td {
    border-radius: 0%;
    text-align: center;
    background: D8EFF1;
    color: rgb(33, 37, 41);
  }
  
  .topHeaderTitle {
    font-family: Raleway !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 30.5px !important;
    line-height: 39px !important;
    letter-spacing: 0.01em !important;
    color: #0a4364 !important;
  }
  
  .textCenterHeading {
    text-align: center !important;
    padding-bottom: 3% !important;
  }
  
  .table-striped > tbody > tr:nth-child(odd) > td,
  .table-striped > tbody > tr:nth-child(odd) > th {
    background-color: #faf0f2 !important;
    /* border-color: black; */
    /* color: white !important; */
  }
  .table-striped > tbody > tr:nth-child(even) > td,
  .table-striped > tbody > tr:nth-child(even) > th {
    background-color: #ffffff !important;
    /* border-color: black; */
    /* color: white !important; */
  }
  
  .noOverflow{
    overflow-y: hidden;
    max-height: none;
  }
  .backArrow:hover {
    cursor: pointer;
  }
  /* @media (min-width: 1300px) and (max-width: 1400px) {
    
  } */