@media (min-width: 1300px) and (max-width: 1400px) {
  .sfss-outer-block {
    height: 48vh !important;
  }
}

@media (min-width: 1400px) {
  .sfss-outer-block {
    height: 56vh !important;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .sfss-outer-block {
    height: 45vh !important;
  }
}
@media (min-width: 1000px) and (max-width: 1190px) {
  .sfss-outer-block {
    height: 46vh;
  }
}

.donutChartNoData {
  /* margin-top: 10%; */
}
