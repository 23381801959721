.cardHeading {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: 0.01em;
  color: #0a4364;
  padding-left: 16% !important;
}

.cardSubHeading {
  font-weight: 500 !important;
  font-size: 19px !important;
  padding-left: 16% !important;
}
