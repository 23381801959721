.userTable {
  width: 70%;
  padding-top: 5%;
}
.Main-table {
  margin-top: 10%;
}

@media (min-width: 768px) and (max-width: 1399px) {
  .userTable {
    width: 60%;
    margin: 120px 0px 30px 0px;
  }
}
.Action_Title {
  font-size: 36px;
  text-decoration: underline;
  color: #3baeb9;
}

.boxesClass {
  padding: 3%;
}

input {
  width: 100%;
}

.buttonAction {
  background-color: white;
  border-radius: 12px;
  width: 50%;
}

.buttonCenter {
  justify-content: center;
  display: flex;
}

/*Override bootstrap placeholder */
::-webkit-input-placeholder {
  font-size: 15px !important;
  color: #abe6ec;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-size: 15px !important;
  color: #abe6ec;
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 15px !important;
  color: #abe6ec;
}

.checkBoxStyle {
  width: 12%;
  height: 3.5vh;
}
