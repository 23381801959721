.btnFlex {
  flex-wrap: nowrap !important;
  display: flex !important;
  vertical-align: middle !important;
  justify-content: center !important;
}

.btnScore {
  /* bottom: 12px; */
  background-color: #ffffff !important;
  border-radius: 15px !important;
  /* padding: 10px !important; */
  display: block;
  margin: 2% !important;
  /* display: flex; */
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  width: 50%;
  color: #3baeb9 !important;
  padding: 0% 5% !important;
  font-weight: 400 !important;
  line-height: 42px !important;
}
.tdWidth {
  padding-left: 0px;
  /* width: 500px; */
}
.tdWidthButton {
  padding-left: 40px;
}
.backArrow {
  cursor: pointer;
}
.marginSet {
  /* margin: 4% 20% 4% 15% !important; */
  flex-wrap: nowrap !important;
  display: flex !important;
  vertical-align: middle !important;
  justify-content: center !important;
}

.agencyDetailsCard {
  position: relative;
  height: 85vh;
  box-sizing: border-box;
  background: #d8eff1;
  border-radius: 15px;
}

.tBodyHeight {
  max-height: none !important;
}
