.searchStyleColor {
  /* color: #2eb5b9; */
  background-color: white;
  font-size: 1rem;
  color: #495057;
}
.search-Div {
  border-radius: 30px;
  background-color: white;
  /* border: 1px solid #dee2e6; */
  margin-top: 30px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(59, 174, 185) !important;
  opacity: 1; /* Firefox */
}

.searchText {
  width: 96% !important;
  border: 1px solid #ced4da;
  margin-left: 2% !important;
}

.search-DivWithBorder {
  border-radius: 30px;
  background-color: white;
  border: 1px solid #dee2e6;
}
