.popup-modal {
  display: block;
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 151, 191, 0.7);
}

.modal_content {
  background-color: white;
  position: absolute;
  top: 17%;
  left: 30%;
  /* width: 40%; */
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 1%;
  border-radius: 5px;
}

/* .modal_content {
  background-color: white;
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 1%;
  border-radius: 5px;
} */
