.table-container {
  width: 100% !important;
}
th {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #0a4364;
}

td:first-child,
th:first-child {
  border-radius: 13px 0 0 13px;
  border-bottom: none;
  word-break: break-word;
}

td:last-child,
th:last-child {
  border-radius: 0 13px 13px 0;
  border-bottom: none;
  word-break: break-word;
}

/* th:first-child {
  border-radius: 1px 0 0 1px;
  border-bottom: none;
}
th:last-child {
  border-radius: 1px 0 0 1px;
  border-bottom: none;
}

td:first-child {
  border-radius: 13px 0 0 13px;
  border-bottom: none;
}

td:last-child {
  border-radius: 0 13px 13px 0;
  border-bottom: none;
} */

table {
  border-collapse: collapse;
  border-radius: 1em;
  overflow: scroll;
  word-break: break-all;
  border-spacing: 22px;
}

td {
  padding: 1em;
  background: D8EFF1;
  border-bottom: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  text-align: center;
  color: #3baeb9;
}

.spacingBetweenRows {
  border-collapse: separate;
  border-spacing: 0 1px;
}

.actionButton {
  border: none;
  background-color: transparent;
}

.actionImg {
  width: 52px;
  height: 18px;
  cursor: pointer;
}
.tableRowStyle {
  padding: "15px";
  border-radius: "15px";
  height: "50px";
  width: "50px";
}

.btnPatient {
  bottom: 12px;
  background: #ffffff;
  border-radius: 15px;
  color: #3baeb9;
  display: flex;
  justify-content: space-between;
  width: 9em;
}

.Completed {
  border-radius: 13px 0 0 13px;
  border-bottom: none;
  background-color: #d8eff1;
  padding-top: 1%;
  padding-bottom: 1%;
}

.Required {
  border-radius: 13px 0 0 13px;
  border-bottom: none;
  background-color: #d8eff1;
  padding-top: 1%;
  padding-bottom: 1%;
}

a {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: #3baeb9;
}

a:hover {
  color: #3baeb9 !important;
}
/**/

/*Set a min width where your table start to look like crap*/
table {
  /* min-width: 600px; */
  border-collapse: collapse;
  border-radius: 1em;
  overflow: scroll;
  word-break: break-all;
  border-spacing: 22px;
}

/*The next 3 sections make the magic happen*/
thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

tbody {
  display: block;
  max-height: 38vh;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  padding-right: "12%";
}
tr {
  border-radius: 24px;
  margin-top: 1%;
}
.alertsymbol {
  width: 700%;
}
.AlertSymbol {
  /* margin-top: -0.5em !important; */
}

@media (min-width: 768px) and (max-width: 992px) {
  .alertsymbol {
    width: 100%;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .alertsymbol {
    width: 80%;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: inherit;
  }
}

/* td {
  overflow: hidden;
  text-overflow: ellipsis;
} */

/*Use the following to make sure cols align correctly*/

/* tr,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
} */

/*Set your columns to where you want them to be, skip the one that you can have resize to any width*/
/* th:nth-child(1),
td:nth-child(1) {
  width: 60%;
}
th:nth-child(2),
td:nth-child(2) {
  width: 50%;
}
th:nth-child(3),
td:nth-child(3) {
  width: 50%;
}
th:nth-child(4),
td:nth-child(4) {
  width: 100%;
}
th:nth-child(5),
td:nth-child(5) {
  width: 30%;
}
th:nth-child(6),
td:nth-child(6) {
  width: 30%;
} */

/* th:nth-child(7), td:nth-child(7) {
  width: 20%;
}
th:nth-last-child(1), th:nth-last-child(1) {
  width: 50%;
}
th:last-child, td:last-child {
  width: 50%;
} */

/* th:nth-child,td:nth-child{
  width: 100%;
} */

/* th:nth-child(5) {
    width: 100%;
}
td:nth-child(5) {
    width: 100%;
} */ ;
