 /* #hideThis {
    
     animation: hideBlock 0s 1s ;
}  */

@keyframes hideBlock {
    from {
        visibility: visible;
    }
  to {
    visibility: hidden;
  }
} 
