.headingStyle {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 2.2;
  color: inherit;
}
/* @media (min-width: 768px) and (max-width: 1100px) {
  .headingStyle {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
  }
} */
.inputFileStyle {
  display: inline-block;
  width: 30%;
  border-bottom: 1px solid;
  padding: 15px 5px 5px;
  box-sizing: border-box;
  height: 50px !important;
  text-align: center;
}
.fileUploadStyle {
  top: 0;
  padding: 10px;
  right: 15%;
  background-color: #d8eff1 !important;
  border: 0 solid #3baeb9 !important;
  color: #0a4364 !important;
  border-radius: 15px !important;
  font-weight: 400 !important;
  margin: 0.5% !important;
}

.downloadStyle {
  position: relative;
  right: -215px;
  color: #3baeb9;
  text-decoration: underline;
  font-size: 1.3rem !important;
}
/* @media (min-width: 768px) and (max-width: 1199px) {
  .downloadStyle {
    position: relative;
    right: -130px !important;
    font-size: 15px;
  }
  .process-button {
    margin-left: 15px;
  }
} */
@media (min-width: 1100px) and (max-width: 1400px) {
  .downloadStyle {
    position: relative;
    right: -150px !important;
    font-size: 15px;
  }
  .process-button {
    margin-left: 15px;
  }
}

.uploadButton {
  background-color: #d8eff1 !important;
  border: 0 solid #3baeb9 !important;
  color: #0a4364 !important;
  border-radius: 15px !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
}

#file-upload-button {
  display: none;
}

.inputfile {
  display: none;
}

.file-button {
  /* position: absolute;
    top: 0px;
    padding: 10px; */
  /* right: 15%; */
  background-color: #d8eff1 !important;
  border: 0px solid #3baeb9 !important;
  color: #0a4364 !important;
  border-radius: 15px !important;
  font-weight: 400 !important;
  margin-left: -1%;
  padding: 12px;
}
@media (min-width: 768px) and (max-width: 992px) {
  .file-button {
    margin-right: -2% !important;
  }
}

.width100 {
  width: 50% !important;
}

.file-box {
  display: inline-block;
  width: 65%;
  border-bottom: 1px solid;
  padding: 15px 15px 5px 5px;
  box-sizing: border-box;
  height: 50px !important;
  text-align: center;
  margin-right: 15px;
  /* //height: calc(2rem - 2px); */
}

.middleWindow {
  width: 27%;
  border: #0a4364 2px solid;
  height: 50%;
  border-radius: 15px;
  position: relative;
  left: 20px;
}

.processFileButton {
  background-color: #d8eff1 !important;
  border: 0px solid #3baeb9 !important;
  color: #0a4364 !important;
  border-radius: 15px !important;
  font-weight: 400 !important;
  margin: 0.5% !important;
  padding: 10px 28px;
  position: relative;
  left: -5% !important;
}
.ImportOuterDiv {
  width: 90%;
  padding-top: 6%;
  padding-left: 10%;
}
@media (min-width: 768px) and (max-width: 1200px) {
  .ImportOuterDiv {
    width: 90%;
    padding-top: 10% !important;
    padding-left: 10%;
  }
}
