.cardButton {
  background-color: white;
  bottom: 12px;
  border-radius: 15px;
  border: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #3baeb9;
}
.cardHeader {
  font-family: Roboto;
  font-style: normal;
  /* font-weight: 500; */
  font-size: 36px;
  line-height: 42px;

  letter-spacing: 0.01em;
  text-decoration-line: underline;
  color: #3baeb9;
}

.nameStyle {
  font-weight: bold !important;
  font-size: 36px !important;
  line-height: 42px !important;
  letter-spacing: 0.01em !important;
  text-decoration: underline !important;
  color: #3baeb9 !important;
}

.whiteCardHeader {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 36px !important;
  line-height: 42px !important;

  letter-spacing: 0.01em !important;
  text-decoration-line: underline !important;
  color: #1c4261 !important;
}

.tableStyle {
  color: #2eb5b9;
  padding: 10px;
}
.sideTableHaead {
  color: #d8eff1;
  line-height: 20px !important;
}
.tbodyBorder {
  border-bottom: 0.5px dashed #2eb5b9;
  color: #2eb5b9;
  font-weight: normal;
  /* width: 58% !important; */
}
td {
  padding: 0.5em;
  font-weight: "normal";
}

.cardtd {
  font-family: Roboto;
  font-weight: 500 !important;
  letter-spacing: 0.01em;
  margin-top: 5%;
  font-style: normal;
  font-size: 18px !important;
  color: #1a3b57;
  line-height: 23px !important;
}

.secondColHeader {
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: 0.01em;
  color: #1c4261;
}
.secondCol2ndHeader {
  font-weight: normal;
  font-size: 25px;
  line-height: 42px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #1c4261;
}

.valueColor {
  color: #2eb5b9;
}

.secondRowWhiteDiv {
  background-color: "white";
  width: "95%";
  border-radius: 15px;
}

.EbuttonStyle {
  border: none;
  background-color: #d8eff1;
  width: 123.96px;
  height: 43px;
  border-radius: 15px 0px 0px 15px;
  color: #3baeb9;
}
.IbuttonStyle {
  border: none;
  background-color: #d8eff1;
  width: 123.96px;
  height: 43px;
  color: #3baeb9;
}
.TbuttonStyle {
  border: none;
  background-color: #d8eff1;
  width: 123.96px;
  height: 43px;
  border-radius: 0px 15px 15px 0px;
  color: #3baeb9;
}

.activeSelectedButton {
  background-color: rgba(59, 174, 185, 0.4) !important;
}

.highRiskstyle {
  color: #ffb400;
  font-weight: 500 !important;
  font-size: 18px;
  margin-left: 2%;
}

.headerNameStyle {
  font-size: 33px;
  font-family: Raleway;
  font-weight: 700;
  color: #1a3b57;
  margin-top: 2%;
  margin-bottom: -2%;
}

.nameChart {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #0a4364;
  margin-top: 5%;
  padding: 0;
  margin-bottom: 0;
  text-align: center !important;
}

.therapistDetailsCard {
  position: relative;
  height: 55vh;
  box-sizing: border-box;
  background: #d8eff1;
  border-radius: 15px;
}
.ps-5 {
  padding-left: 2rem!important;
}
.therapistDetails-table {
  max-height: 45vh;
}
@media (min-width: 768px) and (max-width: 1399px) {
  .therapist-details {
    margin: 100px 0px 30px 0px;
  }
  .therapistDetails-table {
    max-height: 107vh;
  }
  .therapistDetailsCard {
    height: 67vh;
  }
}
@media (min-width: 1400px) {
  .therapistDetails-table {
    max-height: 109vh;
  }
  .therapistDetailsCard {
    height: 62vh;
  }
}
