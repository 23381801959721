.outer-table {
  width: 70%;
  padding-top: 6%;
}
.sfssHeading {
  width: 15em;
}
.sfssTableData {
  width: 22em;
}

.scoreData {
  padding-left: 34px;

  position: relative;
  left: 49px;
}

.scoreData1 {
  padding-left: 79px;
}

.scoreDataCaregiver {
  position: relative;
  left: 59px;
}
/* .tbodyBorder {
  border-bottom: 1.2px dashed;
} */
/* @media (min-width: 1200px) and (max-width: 1399px) {
  .outer-table {
    width: 79%;
    
  }
}
@media (max-width: 1450px) {
  .outer-table {
    width: 85%;
   
  }
} */

/* .tableStyle {
  color: #2eb5b9;
  padding: 10px;
}
.sideTableHead {
  color: #d8eff1;
  line-height: 20px !important;
}
.tbodyBorder {
  border-bottom: 0.5px dashed #2eb5b9 !important;
  color: #2eb5b9 !important;
  font-weight: normal !important;
}
.td_small {
  padding: 0.5em;
  font-weight: "normal";
} */

/*Main Table*/

/* th {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  width: 16%;
  line-height: 21px;
  text-align: center;
  color: #0a4364;
} */

/* td:first-child,
th:first-child {
  border-radius: 13px 0 0 13px;
  border-bottom: none;
} */

/* td:last-child,
th:last-child {
  border-radius: 0 13px 13px 0;
  border-bottom: 0.5px dashed #2eb5b9 !important;
  border-bottom: none;
} */

/* table {
  border-collapse: collapse;
  border-radius: 1em;
  overflow: scroll;
  word-break: break-all;
  border-spacing: 22px;
} */

/* td {
  padding: 1em;
  background: D8EFF1;
  border-bottom: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #3baeb9;
} */

/* .spacingBetweenRows {
  border-collapse: separate;
  border-spacing: 0 15px;
} */

/* .actionButton {
  border: none;
  background-color: transparent;
} */

/* .actionImg {
  width: 52px;
  height: 18px;
  cursor: pointer;
} */
/* .tableRowStyle {
  padding: "15px";
  border-radius: "15px";
  height: "50px";
  width: "150px";
} */

/* .btnPatient {
  bottom: 12px;
  background: #ffffff;
  border-radius: 15px;
  color: #3baeb9;
} */

/* .Completed {
  border-radius: 13px 0 0 13px;
  border-bottom: none;
  background-color: #d8eff1;
  padding-top: 1%;
  padding-bottom: 1%;
} */

/* .Required {
  border-radius: 13px 0 0 13px;
  border-bottom: none;
  background-color: #d8eff1;
  padding-top: 1%;
  padding-bottom: 1%;
} */

/* .sfssHeader {
  display: inline-flex;
  position: relative;
  left: -43%;
} */
